<template>
  <div class="homepage-features py-5 text-center bg-light">
    <img
      :src="myLogoSrc"
      alt="my-logo"
      style="display: block; margin-left: auto; margin-right: auto; width: 50%"
    />
    <div class="container">
      <div class="row mb-3">
        <div class="col">
          <h2>{{ title }}</h2>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <ul>
            <li v-for="pod in pods" :key="pod.title">
              <div style="display: block; margin-left: auto; margin-right: auto; width: 50%">
                <!-- SVG Icon Start-->
                <img
                  v-if="pod.icon.localFile.name === 'iconChart'"
                  :src="iconChart"
                  alt="my-logo"
                  style="display: block; margin-left: auto; margin-right: auto; width: 50%"
                />
                <img
                  v-if="pod.icon.localFile.name === 'iconChat'"
                  :src="iconChat"
                  alt="my-logo"
                  style="display: block; margin-left: auto; margin-right: auto; width: 50%"
                />
                <img
                  v-if="pod.icon.localFile.name === 'iconChecklist'"
                  :src="iconChecklist"
                  alt="my-logo"
                  style="display: block; margin-left: auto; margin-right: auto; width: 50%"
                />
                <img
                  v-if="pod.icon.localFile.name === 'iconClipboard'"
                  :src="iconClipboard"
                  alt="my-logo"
                  style="display: block; margin-left: auto; margin-right: auto; width: 50%"
                />
                <img
                  v-if="pod.icon.localFile.name === 'iconGauge'"
                  :src="iconGauge"
                  alt="my-logo"
                  style="display: block; margin-left: auto; margin-right: auto; width: 50%"
                />
                <img
                  v-if="pod.icon.localFile.name === 'iconHouse'"
                  :src="iconHouse"
                  alt="my-logo"
                  style="display: block; margin-left: auto; margin-right: auto; width: 50%"
                />
                <img
                  v-if="pod.icon.localFile.name === 'iconTools'"
                  :src="iconTools"
                  alt="my-logo"
                  style="display: block; margin-left: auto; margin-right: auto; width: 50%"
                />
              </div>
              <!-- <SVG Icon End /> -->
              <h3>{{ pod.title }}</h3>
              <p>{{ pod.text }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myLogoSrc from '@/images/bg-illustration.svg';
import iconChart from '@/images/icons/icon-chart.svg';
import iconChat from '@/images/icons/icon-chat.svg';
import iconChecklist from '@/images/icons/icon-checklist.svg';
import iconClipboard from '@/images/icons/icon-clipboard.svg';
import iconGauge from '@/images/icons/icon-gauge.svg';
import iconHouse from '@/images/icons/icon-house.svg';
import iconTools from '@/images/icons/icon-tools.svg';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    pods: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      myLogoSrc,
      iconChart,
      iconChat,
      iconChecklist,
      iconClipboard,
      iconGauge,
      iconHouse,
      iconTools,
    };
  },
};
</script>
