<template>
  <homepage-about :content="content" />

  <homepage-features v-if="acf" :title="acf.discovery_title" :pods="acf.discovery_pods" />
</template>

<script>
// import SEO from './SEO.vue'
import HomepageAbout from './HomepageAbout.vue';
import HomepageFeatures from './HomepageFeatures.vue';

export default {
  name: 'Homepage',
  components: {
    // SEO,
    HomepageAbout,
    HomepageFeatures,
  },
  data() {
    return {
      banner: true,
      content:
        'The <b>TrustMark Property Hub</b> is a log book for works completed in your home. Find paperless evidence of work completed, certificates and guarantees at the click of a button. All stored securely online for your records',
      title: 'Homepage',
      acf: {
        discovery_title: 'What you will discover with TrustMark Property Hub',
        discovery_pods: [
          {
            title: 'Portfolio View',
            text: 'Property associated with your account.',
            icon: {
              localFile: {
                url: 'images/icons/icon-chat.svg',
                name: 'iconHouse',
              },
            },
          },
          // {
          //   title: 'Assessment',
          //   text: 'pod text 2',
          //   icon: {
          //     localFile: {
          //       url: 'images/icons/icon-clipboard.svg',
          //       name: 'iconChat',
          //     },
          //   },
          // },
          {
            title: 'Calendar of Works Completed',
            text: 'Completion date of works.',
            icon: {
              localFile: {
                url: 'images/icons/icon-house.svg',
                name: 'iconChart',
              },
            },
          },
          {
            title: 'Installer Information',
            text: 'Registered name of installer.',
            icon: {
              localFile: {
                url: 'images/icons/icon-tools.svg',
                name: 'iconTools',
              },
            },
          },
          {
            title: 'Installation Data',
            text: 'Make, model, guarantees etc',
            icon: {
              localFile: {
                url: 'images/icons/icon-gauge.svg',
                name: 'iconChecklist',
              },
            },
          },
          {
            title: 'Testing & commissioning',
            text: 'Supplies test and commissioning records for the property.',
            icon: {
              localFile: {
                url: 'images/icons/icon-checklist.svg',
                name: 'iconGauge',
              },
            },
          },
          {
            title: 'Handover',
            text: 'Peace of mind with records, documentation and claims of conformity.',
            icon: {
              localFile: {
                url: 'images/icons/icon-chart.svg',
                name: 'iconClipboard',
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    if (this.loggedIn && this.authenticated) {
      // this.$router.push('/properties');
    }
  },
};
</script>
