<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="container py-5 homepage-about">
    <div class="">
      <div class="row justify-content-center">
        <div class="col col-md-9 text-center" v-html="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>
